var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row" }, [
    _c(
      "div",
      { staticClass: "vx-col w-full mb-base" },
      [
        _c(
          "vx-card",
          [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showSearchButton,
                        expression: "showSearchButton",
                      },
                    ],
                    staticClass: "float-right",
                    attrs: {
                      color: "primary",
                      type: "filled",
                      "icon-pack": "feather",
                      icon: "icon-search",
                    },
                    on: { click: _vm.getSearch },
                  }),
                  _c("vs-input", {
                    staticClass:
                      "float-right sm:mr-1 mr-0 sm:w-auto w-1/2 sm:order-normal order-3 sm:mt-0 mt-2",
                    attrs: { placeholder: "Search Web Ref..." },
                    on: { input: _vm.updateSearchQuery },
                    model: {
                      value: _vm.searchQuery,
                      callback: function ($$v) {
                        _vm.searchQuery = $$v
                      },
                      expression: "searchQuery",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("ag-grid-vue", {
              ref: "agGridTable",
              staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
              attrs: {
                components: _vm.cellRendererComponents,
                columnDefs: _vm.columnDefs,
                defaultColDef: _vm.defaultColDef,
                rowData: _vm.enquiryViewerData,
                gridOptions: _vm.gridOptions,
                rowSelection: "single",
                pagination: true,
                paginationPageSize: _vm.paginationPageSize,
                suppressPaginationPanel: true,
              },
              on: {
                "selection-changed": _vm.onSelectionChanged,
                "grid-size-changed": _vm.onGridSizeChanged,
              },
            }),
            _c("vs-pagination", {
              attrs: { total: _vm.totalPages, max: 5 },
              model: {
                value: _vm.currentPage,
                callback: function ($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }